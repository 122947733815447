<template>
  <div class="card">
    <h2 class="card-header">
      conversationGuests
    </h2>
    <div class="card-body">
      <DataTable
        :fields="conversationGuestFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-list="getList"
        :is-loading="$isLoading('getListConversationGuest')"
        :actions="conversationGuestActions"
        :empty-message="'No conversationGuests'"
        class="w-full"
      />

      <div class="flex flex-row-reverse mt-4">
        <button
          :disabled="$isLoading('getListConversationGuest')"
          @click="$refs.newConversationGuestDialog.openDialog()"
        >
          New Conversation Guest
        </button>
      </div>
    </div>

    <NewConversationGuestDialog ref="newConversationGuestDialog" />
    <EditConversationGuestDialog ref="editConversationGuestDialog" />
    <DeleteConversationGuestDialog ref="deleteConversationGuestDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import DataTable from '#ui/components/DataTable';
import { CONVERSATION_GUEST_MODEL_FIELDS } from '#features/conversationGuests/lib/models/ConversationGuestModel';
import NewConversationGuestDialog from '#features/conversationGuests/ui/dialogs/NewConversationGuestDialog';
import EditConversationGuestDialog from '#features/conversationGuests/ui/dialogs/EditConversationGuestDialog';
import DeleteConversationGuestDialog from '#features/conversationGuests/ui/dialogs/DeleteConversationGuestDialog';

// Setup

const store = useStore();
const currentPage = computed(() => store.state.ConversationGuests.currentPage);
const totalPages = computed(() => store.state.ConversationGuests.totalPages);
const conversationGuestFields = computed(() => omit(CONVERSATION_GUEST_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('ConversationGuests/getList', values),
  'getListConversationGuest',
  getCurrentInstance(),
);

// Row actions

const editConversationGuestDialog = ref(null);
const openEditDialog = (conversationGuest) => {
  editConversationGuestDialog.value.openDialog(conversationGuest);
};

const deleteConversationGuestDialog = ref(null);
const openDeleteDialog = (conversationGuest) => {
  deleteConversationGuestDialog.value.openDialog(conversationGuest);
};

const conversationGuestActions = [
  { name: 'Edit Conversation Guest', fn: openEditDialog },
  { name: 'Delete Conversation Guest', fn: openDeleteDialog },
];
</script>
